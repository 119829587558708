import React from "react";
import { Switch, BrowserRouter as Router ,Route } from "react-router-dom";
import {AptDetail} from "./AptDetail";
import AptviewDetail from "./AptviewDetail";
import { DoctorAppointmentDetail,PatientAppointmentDetail } from '../../../@types/Types';
import { Prescription } from "../Prescription";
import { LabReport } from "../LabReport";
import { Notes } from "../Notes";


export const DoctorAppointmentRoute : React.FunctionComponent<{
    aptDetail: DoctorAppointmentDetail | undefined
  }> = (props) => {

    return (
        <div className="mg-at">
            {/* <Router> */}
                <Switch>
                    <Route path={`/appointments/:id/add-prescription`}>
                        <Prescription aptDetail={props.aptDetail}/>
                    </Route>
                    <Route path={`/appointments/:id/add-notes`}>
                        <Notes aptDetail={props.aptDetail}/>
                    </Route>
                    <Route path={`/appointments/:id/add-lab-report`}>
                          <LabReport aptDetail={props.aptDetail}/>
                    </Route>
                    <Route exact path={"/appointments/:id"}>
                        <AptDetail aptDetail={props.aptDetail}/>
                    </Route>
                </Switch>
            {/* </Router> */}
        </div>
    );
}

export const PatientAppointmentRoute : React.FunctionComponent<{
    aptDetail: PatientAppointmentDetail | undefined
  }> = (props) => {

    return (
        <div className="mg-at">
            {/* <Router> */}
                <Switch>
                    <Route exact path={"/appointments/:id"}>
                        <AptviewDetail aptDetail={props.aptDetail}/>
                    </Route>
                </Switch>
            {/* </Router> */}
        </div>
    );
}