import React from 'react';
import { DoctorDetail,
    PatientDetail,
    PersonalInfo,
    Option,
    SpecializationInfo,
    BankInfo,
  ElementProps,
  BoolWithMessage } from '../../@types/Types';
import { FieldValues,Control,Controller,UseFormMethods,FieldError,DeepMap } from 'react-hook-form';
import {ProfileHeader} from "../Elements/Common";
import {ShippingIcon,PersonalIcon,BankIcon, SpecializationIcon} from "../../image/Icons";
// import MultiDropDown from "../Elements/MultiDropDown";
import get from "lodash/get";
import { ResourceContext } from "../Context/ContextAPI";
import shipping from "../../image/images/Vector-15-1.png";
import { MultiSelect } from "react-multi-select-component";

const gender : Option[] = [{label:"Male",value:"Male"},{label:"Female",value:"Female"},{label:"Intersex",value:"Intersex"}]
const language : Option[] = [{label:"Tamil",value:"Tamil"},{label:"Hindhi",value:"Hindhi"},{label:"English",value:"English"}];

export const PatientPersonalDetail : React.FunctionComponent<{
  method:UseFormMethods<Record<string, any>>,
}> = (props) => {
  const useContxtData = React.useContext(ResourceContext);
  const data = useContxtData?.context.patientDetail;
  const {method} = props;

    return (
        <div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("text","first_name","first name",true)} value={data?.first_name} className='btn-shadow'/>
                </div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("text","last_name","last name",true)} value={data?.last_name} className='btn-shadow'/>
                </div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("number","age","age",true)} value={data?.age} className='btn-shadow'/>
                </div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("select","gender","gender",true,gender)} value={data?.gender} className='btn-shadow'/>
                </div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("text","email","email",true)} value={data?.email} className='btn-shadow'/>
                </div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("text","phone_number","phone number",true)} value={data?.phone_number} className='btn-shadow'/>
                </div>
                <div>
                    <InputComponent method={method} field={fieldConstruct("text","emergency_contact","emergency contact",true)} value={data?.emergency_contact} className='btn-shadow'/>
                </div>
        </div>
    );
}

export const PatientShippingAddress : React.FunctionComponent<{
  method:UseFormMethods<Record<string, any>>,
}> = (props) => {
  const useContxtData = React.useContext(ResourceContext);
  const data = useContxtData?.context.patientDetail;
  const {method} = props;

    return (
        <div>
            <ProfileHeader tittle={"Shipping Address"} icon={shipping}/>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","address_line_2","address line 2",true)} value={data?.address_line_2} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","city","city",true)} value={data?.city}  className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","state","state",true)} value={data?.state} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","pin_code","pin code",true)} value={data?.pincode}  className='btn-shadow'/>
            </div>
        </div>
    );
}


export const DoctorBankDetail : React.FunctionComponent<{
  method:UseFormMethods<Record<string, any>>,
}> = (props) => {
  const useContxtData = React.useContext(ResourceContext);
  const data = useContxtData?.context.doctorDetail;
  const {method} = props;

    return (
        <>
          <div>
              <InputComponent method={method} field={fieldConstruct("text","bank_account_number","bank account number",true)} value={data?.bank_account_number} className='btn-shadow'/>
          </div>
          <div>
              <InputComponent method={method} field={fieldConstruct("text","ifsc","ifsc code",true)} value={data?.ifsc} className='btn-shadow'/>
          </div>
          <div>
              <InputComponent method={method} field={fieldConstruct("text","upi_id","upi id",true)} value={data?.upi_id} className='btn-shadow'/>
          </div>
        </>
    );
}

export const DoctorSpecialisationDetail : React.FunctionComponent<{
  method:UseFormMethods<Record<string, any>>,
}> = (props) => {
  const {method} = props;
  const useContxtData = React.useContext(ResourceContext);
  const data = useContxtData?.context.doctorDetail;

    return (
        <div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","specialization","specialization",true)} value={data?.specialization} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","degree","degree",true)} value={data?.degree} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","registration_number","registration number",true)} value={data?.registration_number} className='btn-shadow'/>
            </div>
        </div>
    );
}

export const DoctorPersonalDetail : React.FunctionComponent<{
  method:UseFormMethods<Record<string, any>>,
}> = (props) => {
    const useContxtData = React.useContext(ResourceContext);
    const data = useContxtData?.context.doctorDetail;
    const { method } = props;

    return (
        <>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","photo","photo",false)} value={data?.photo}  className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","last_name","last name",true)} value={data?.last_name} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","prefix","prefix",false)} value={data?.prefix} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("select","gender","gender",true,gender)} value={data?.gender} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("date","dob","dob",true)} value={data?.dob} className='btn-shadow'/>
            </div>
            <div>
                <InputComponent method={method} field={fieldConstruct("text","city","city",false)} value={data?.city} className='btn-shadow'/>
            </div>
            <div>
                <MultiDropDown name="languages" control={method.control} options={language} value={data && data.languages ? data.languages  : []}/>
            </div>
        </>
    );
}


export const InputComponent : React.FunctionComponent<{
    field: ElementProps,
    method:UseFormMethods<Record<string, any>>,
    value: any,
    className?: string
   }> = (props) => {
      const useContxtData = React.useContext(ResourceContext);

      const handleInputChange = (event:any) => {
        const { name, value } = event.target;
        if(useContxtData != null && useContxtData.context.doctor == true){
          var doctorDetail : DoctorDetail = {...useContxtData.context.doctorDetail};
          doctorDetail = {...doctorDetail,[name]:value};
          useContxtData.setContext({...useContxtData.context,doctorDetail});
        }else if(useContxtData != null && useContxtData.context.doctor == false){
          var patientDetail : PatientDetail = {...useContxtData.context.patientDetail};
          patientDetail = {...patientDetail,[name]:value};
          useContxtData.setContext({...useContxtData.context,patientDetail});
        }
      }
        const { field,method } = props;

        switch (field.type) {
            case "select":
              return <Controller
              control={props.method.control}
              name={field.name}
              ref={method.register({
                required: field.required,
              })}
              render={({value,onChange }) => (
                <select
                  className={"w-input " + (typeof props.className != "undefined" && props.className != null ? props.className : "")}
                  name={field.name}
                  onChange={(e:any)=>{
                    handleInputChange(e);
                    onChange(e);
                  }}
                  value={value}>
                    {props.field.options?.map((ed,index)=>{
                        return <option value={ed.value}>{ed.label}</option>
                    })}
                </select>
              )}
              defaultValue={typeof props.value != "undefined" ? props.value : ""}
              />
            default:
              return <input
              defaultValue={typeof props.value != "undefined" ? props.value : ""}
              style={{
                ...(InputErrorDesign(method.errors,field.name)),
              }}
              placeholder={props.field.label.toUpperCase()}
              onChange={(e:any)=>handleInputChange(e)}
              autoComplete="off"
              className={"w-input " + (typeof props.className != "undefined" && props.className != null ? props.className : "")}
              type={field.type}
              name={field.name}
              ref={method.register({
                required: field.required,
                ...(field.pattern
                  ? {
                    pattern:
                      { value: new RegExp(field.pattern.value), message: field.pattern.message }
                  }
                  : null),
                ...(field.max ? { max: field.max } : null),
                ...(field.min ? { min: field.min } : null),
                ...(field.maxLength ? { maxLength: field.maxLength } : null),
                ...(field.minLength ? { minLength: field.minLength } : null),
              })}
            />
        }
 }

export const fieldConstruct = (type:string,name:string,label:string,req:boolean,option?:Option[]) => {
    const required : BoolWithMessage = {
      value : req,
      message : "This is a required field"
    }
    const field : ElementProps = {
      name: name, // element id or name
      label: label, // label or placeholder
      placeholder: label, // label or placeholder
      type: type, // input type example:
      toggle: false,
      elementType:'',
      required:  required,
      options: option // ; sepearated by a value
    };
    return field;
  }

 export const InputErrorDesign = (errors: DeepMap<Record<string, any>, FieldError>, fieldName: string) => {
  const errorMessage = (get(errors, fieldName) as FieldError)?.message;
  return typeof errorMessage != "undefined" ? { border: `2px solid #EB5757`,
  background: ` white`} : null
}

export const ElementName = (fieldName: string, propertyName: string, index: number, indexCheck: Boolean): string => {
  // return (typeof index !== 'undefined' && indexCheck)? propertyName + '[' + index + '].' + fieldName :  propertyName.length > 0  ? propertyName + '.' + fieldName : '.' + fieldName
  // console.log((typeof index !== 'undefined' && indexCheck)? propertyName + '*' + index + '*' + fieldName : propertyName.length > 0  ? propertyName + '*' + fieldName : fieldName);

  // ----------------old below-----------------
  // return (typeof index !== 'undefined' && indexCheck) ? propertyName + '*' + index + '*' + fieldName : propertyName != null && propertyName.length > 0 ? propertyName + '*' + fieldName : fieldName
  // --------------new below--------------------
  return (typeof index !== 'undefined' && indexCheck == true) ? `${propertyName}[${index}].${fieldName}` : propertyName != null && propertyName.length > 0 ? `${propertyName}.${fieldName}` : fieldName
}


const MultiDropDown : React.FunctionComponent<{
  name        :   string,
  control     :   any,
  options     :   Option[],
  value       :   Option[]
  index?      : number,
  parentName?  : string
}> = (props) => {

  const useContxtData = React.useContext(ResourceContext);

  const handleInputChange = (event:any,name:string) => {
    if(useContxtData != null && useContxtData.context.doctor == true){
      var doctorDetail : DoctorDetail = {...useContxtData.context.doctorDetail};
      doctorDetail = {...doctorDetail,[name]:event};
      useContxtData.setContext({...useContxtData.context,doctorDetail});
    }else if(useContxtData != null && useContxtData.context.doctor == false){
      var patientDetail : PatientDetail = {...useContxtData.context.patientDetail};
      patientDetail = {...patientDetail,[name]:event};
      useContxtData.setContext({...useContxtData.context,patientDetail});
    }
  }
  const name = typeof props.index !== "undefined" && typeof props.parentName !== "undefined" ? `${props.parentName}[${props.index}].${props.name}` : props.name;
  const [option, setOption] = React.useState<Option[]>([]);
  React.useEffect(()=>{
      setOption(props.options);
  },[])

  return (
      <Controller
          control={props.control}
          name={name}
          defaultValue={props.value}
          render={({
              onChange, value
          }) => (
              <MultiSelect
                  options={option}
                  value={value}
                  onChange={(e:any)=>{
                      handleInputChange(e,name);
                      onChange(e);
                  }}
                  labelledBy={props.name}
              />
          )}
          />
  );
}
