import * as React from 'react';
import "../../styles/common.css";
import { ResourceContext } from "../Context/ContextAPI";
import wordmark from "../../image/images/Wordmark-Black-1.png";
const eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const Header = () => {

  const useContxtData = React.useContext(ResourceContext);

  const handleLogout = () => {
    if(useContxtData != null){
      eraseCookie(useContxtData.context.doctor ? "d_token" : "p_token");
      window.localStorage.setItem('rh-token', null);
      window.localStorage.setItem('rh-token-type', null);
    }
    window.location.href = "/login"
  }

  return (
  // <div className="header">
    <div data-animation="default" data-collapse="all" data-duration="400" data-easing="ease" data-easing2="ease" data-no-scroll="1" role="banner" className="w-nav section-navbar_menu">
      <div className="section-navbar_menu-wrapper w-container">
        <a href="#" id="w-node-_141333b0-f15e-7789-05d3-cfe050eedbc0-50eedbbf" className="w-nav-brand">
          <img src={wordmark} loading="lazy" id="w-node-a8ef372e-cdd2-44ac-353c-b3724053a695-50eedbbf" alt="" className="image-6"/>
        </a>
        <nav role="navigation" className="nav-menu-2 w-nav-menu">
          <div className="section-divider"></div>
          <a href="https://www.rockethealth.app/mental-health" target="_blank" className="nav-link-2 w-nav-link">Mental Health</a>
          <a href="https://www.rockethealth.app/mens-sexual-health" target="_blank" className="nav-link-2 w-nav-link">Men&#x27;s Health</a>
          <a href="https://www.rockethealth.app/birth-control" target="_blank" className="nav-link-2 w-nav-link">Women&#x27;s Health</a>
          <a href="https://www.rockethealth.app/how-it-works" target="_blank" className="nav-link-2 w-nav-link">How it works</a>
          <a href="https://www.rockethealth.app/for-business/for-business" target="_blank" className="nav-link-2 w-nav-link">For Business</a>
          <a href="https://www.rockethealth.app/about-us" target="_blank" className="nav-link-2 w-nav-link">About Us</a>
          <a href="https://www.rockethealth.app/cancel-stigma" target="_blank" className="nav-link-2 w-nav-link">Cancel Stigma</a>
        </nav>
        <div className="menu-button-2 w-nav-button">
          <div className="icon-2 w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
  // </div>
  );
}

export default Header;