import React from 'react';
import { FieldValues,Control,Controller,useForm,
    UseFormMethods,FieldError,DeepMap } from 'react-hook-form';
import {InputComponent,fieldConstruct,InputErrorDesign} from "../../WizardForm/BasicDetail";
import axios from 'axios';
import logo from "../../../image/images/Wordmark-Black-1.png";

const Signup = () => {

    const method = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data:any) => {
        console.log(data);
        if(data.password == data.confirm_password){
            setResponse("");
            axios.post<any>(process.env.REACT_APP_API_URL + '/api/doctor-signup',data,{
                withCredentials: true
            })
            .then((response) => {
                console.log(response.data);
                setResponse("Operation Completed...!");
                window.location.href = `/appointments`;
            }).catch((error) => {
                console.log(error);
                setResponse(error.message.toString());
            });
        }else{
            setResponse("password does not match");
        }
    }

    return(
        <div className="page-wrapper">
            <div className="main-wrapper">
            <div className="main-wrapper_mobile">
                <div className="login-nav">
                    <img src={logo} loading="lazy" alt="" className="image-8"/>
                </div>
                <div className="page-padding">
                    <div className="section-login-screen">
                        <div className="section-login-screen">
                            <div className="section-login-wrapper">
                            <h1 className="text-medium">Sign up</h1>
                            </div>
                            <div className="margin-top-40">
                            <form onSubmit={method.handleSubmit(onsubmit)}>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("text","first_name","first name",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("text","email","email id",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("password","password","password",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("text","confirm_password","confirm password",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("text","phone_number","phone number",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div style={{textAlign:'center',paddingTop:'1rem'}}>
                                    <input className="section_button-wrapper inactive-btn w-button" type="submit" value="Sign Up" />
                                </div>
                                <div style={{textAlign:'center',paddingTop:'1rem'}}>
                                    {response}
                                </div>
                            </form>
                            </div>
                            <div className="margin-top-40">
                            <div className="div-block-4">
                                <a href="/doctor-login" className="link-block-4 w-inline-block">
                                <h1 className="text-size-regular text-weight-regular"><span className="text-span-2">Already have an account?</span>
                                <span className="text-span-3"> Login</span></h1>
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-login-footer">
                    <p className="paragraph-2">By creating an account, you agree to our
                    <span className="is-orange">T&amp;Cs</span></p>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Signup;
