import React,{useState,useEffect} from "react";
import { MultiSelect } from "react-multi-select-component";
import {Controller} from "react-hook-form";
import { Option } from "../../@types/Types";


const MultiDropDown : React.FunctionComponent<{
    name        :   string,
    control     :   any,
    options     :   Option[],
    value       :   Option[]
    index?      : number,
    parentName?  : string
}> = (props) => {
    

    const [option, setOption] = useState<Option[]>([]);
    console.log("value",props.value);
    useEffect(()=>{
        setOption(props.options);
    },[])
    
    return (
        <Controller
            control={props.control}
            name={typeof props.index !== "undefined" && typeof props.parentName !== "undefined" ? `${props.parentName}[${props.index}].${props.name}` : props.name}
            defaultValue={props.value}
            render={({
                onChange, value
            }) => (
                <MultiSelect
                    options={option}
                    value={value}
                    onChange={(e:any)=>{
                        onChange(e);
                    }}
                    labelledBy={props.name}
                />
            )}
            />
    );
}

export default MultiDropDown;