import React from 'react';
import { DoctorAppointmentDetail } from '../../../@types/Types';
// import { useParams } from 'react-router-dom'
// import axios from 'axios';
import { NavLink } from "react-router-dom";
import {Goback,CalenderIcon} from "../../../image/Icons";
import chat from "../../../image/images/chat_black_24dp-4-1-1.png";
import calender from "../../../image/images/event_black_24dp-4-4-1.png";
import mdeical from "../../../image/images/medication_black_24dp-1-2-1.png";
import description from "../../../image/images/description_black_24dp-6-2-1.png";
import help from "../../../image/images/help_black_24dp-3-2-1.png";
import schedule from "../../../image/images/schedule_black_24dp-2-1.png";
import goback from "../../../image/images/Group-16737-2-1.png";
import moment from 'moment';

const nullChecker = (value :any) => {
    return value != null ? value : ""
}

// export const AptDetail = () => {
export const AptDetail : React.FunctionComponent<{
    aptDetail: DoctorAppointmentDetail | undefined
  }> = (props) => {
    return(
        props.aptDetail ?
            <div className='page-padding'>
                <div className="section-appointment-details-copy">
                    <div id="w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4680-a7396e61" className="personal-info-wrapper">
                        <NavLink  to={`/appointments`} className="w-inline-block">
                            <img src={goback} loading="lazy" alt="" className="icon-size-back"/>
                        </NavLink>
                        <h1 id="w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4682-a7396e61" className="text-heading">
                            Appointment details
                        </h1>
                    </div>
                </div>
                <div className='section-divider'></div>
                <div className="section-profile-personal-info-form">
                    <div className="we-have-sent-you-the-otp-to-83009-88763">
                        <div className="section-appointment-detail_list">
                            <div>
                                <div className="w-layout-grid section-appointment_layout">
                                    <div id="w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4697-a7396e61" className="section-appointment-details-copy">
                                        <div id="w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4698-a7396e61" className="section-checkins_list-item-details">
                                            <div className="list-item-details_dates">
                                                <div className="is-underlined">PATIENT INFO</div>
                                            </div>
                                            <div className="text-heading">
                                                {props.aptDetail.Patient.first_name
                                                + " "
                                                + nullChecker(props.aptDetail.Patient.last_name)}
                                            </div>
                                            <div className="list-item-details_dates">
                                                <div className="text_checkins-date">
                                                    { nullChecker(props.aptDetail.Patient.gender)
                                                    + nullChecker(props.aptDetail.Patient.age).length > 0 ? " , "
                                                    + nullChecker(props.aptDetail.Patient.age)
                                                    + " years" : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div id="w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e46a4-a7396e61" className="section_button-wrapper secondary-btn">
                                            <img src={chat} loading="lazy" width="25" id="w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e46a5-a7396e61" alt="" className="image-3"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-divider"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="section-appointment_list-item">
                        <div className="w-layout-grid section-appointment_layout">
                            <div id="w-node-_620fb22b-65a7-9252-98a0-bcd189bcf394-a7396e61" className="floating-number-copy">
                                <div className="text-floating">#01</div>
                            </div>
                            <div id="w-node-_620fb22b-65a7-9252-98a0-bcd189bcf397-a7396e61" className="section-appointment_list-item-details">
                                <div className="list-item-details_dates">
                                    <img src={calender} loading="lazy" alt="" className="calendar-copy"/>
                                    <div className="text-heading">{moment(props.aptDetail.appointment_datetime).format('LT')}</div>
                                    <div className="section_appointment-divider"></div>
                                </div>
                                <div className="list-item-details_dates">
                                    <div className="text_checkins-date">Added at {moment(props.aptDetail.booking_datetime).format('LLL')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="section-appointment_list-buttons">
                            <a href="https://wa.me/+918971768946" target="_blank" className="section-appointment_button secondary-btn w-button">Reschedule</a>
                            <a href={props.aptDetail.appointment_link} className="section-appointment_button primary-btn w-button">Attend</a>
                        </div>
                    </div>
                </div>
                <div className="section-appointment_list-item">
                    <div className="section-appointment-details-item">
                        <NavLink to={`/appointments/${props.aptDetail.id}/add-prescription`} className="link-block-7 w-inline-block">
                            <div className="section-appointment-details-items">
                            <div className="text-heading">Add prescription</div>
                            <div className="we-have-sent-you-the-otp-to-83009-88763">
                                <img src={mdeical} loading="lazy" alt="" className="icon-size-large"/></div>
                            </div>
                        </NavLink>
                        <NavLink to={`/appointments/${props.aptDetail.id}/add-notes`} className="link-block-7 w-inline-block">
                                <div className="section-appointment-details-items _2">
                                <div className="text-heading">Add notes</div>
                                <div className="we-have-sent-you-the-otp-to-83009-88763">
                                    <img src={description} loading="lazy" alt="" className="icon-size-large"/>
                                    </div>
                                </div>
                        </NavLink>
                        <NavLink to={`/appointments/${props.aptDetail.id}/add-lab-report`} className="link-block-7 w-inline-block">
                                <div className="section-appointment-details-items _3">
                                <div className="text-heading">History &amp; Lab reports</div>
                                <div className="we-have-sent-you-the-otp-to-83009-88763">
                                    <img src={help} loading="lazy" alt="" className="icon-size-large"/>
                                    </div>
                                </div>
                        </NavLink>
                        <div className="link-block-7 w-inline-block">
                            <div className="section-appointment-details-items _4">
                            <div className="text-heading">Previous <br/> Appointments</div>
                            <div className="we-have-sent-you-the-otp-to-83009-88763">
                                <img src={schedule} loading="lazy" alt="" className="icon-size-large"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-appointment-time">
                    <div className="section-appointment-time-wrapper-copy">
                        <div className="section-appointment-follow-up">
                            <h1 className="appointment-details">Follow up</h1>
                        </div>
                        <div className="section-appointment-time-date">
                            <div className="section-appointment-time-date-left">
                                <h1 className="appointment-details">Choose a date</h1>
                            </div>
                            <div className="div-block-2"></div>
                            <div className="section-appointment-time-date-right">
                                <img src={calender} loading="lazy" alt="" className="icon-size-tiny"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-divider"></div>
                <div className="section-appointment-time">
                    <div className="section-appointment-time-wrapper-copy">
                        <div className="section-appointment-follow-up">
                        <h1 className="appointment-details">Refer to</h1>
                        </div>
                    </div>
                    <div className="margin-top-10">
                        <div data-hover="false" data-delay="1" className="dropdown-2 w-dropdown">
                        <div className="dropdown w-dropdown-toggle">
                            <div className="icon w-icon-dropdown-toggle"></div>
                            <div>Select</div>
                        </div>
                        <nav className="dropdown-list w-dropdown-list">
                            <a href="#" className="dropdown-link w-dropdown-link">Male</a>
                            <a href="#" className="dropdown-link w-dropdown-link">Female</a>
                            <a href="#" className="dropdown-link w-dropdown-link">Others</a>
                        </nav>
                        </div>
                        <div className="section_button-wrapper margin-top-20">
                            <a href="#" className="section_button-wrapper primary-btn w-button">Submit</a>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div>Appoitment details can't be found</div>
    );
}