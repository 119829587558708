import React,{useEffect} from 'react';
import {Goback,Note} from "../../image/Icons";
import { useForm,useFieldArray,Controller,Control } from 'react-hook-form'
import { Option,TNotes } from '../../@types/Types';
import axios from 'axios';
import {Heading,PersonalInfo,SubHeading} from "../Elements/Common";
import { DoctorAppointmentDetail } from '../../@types/Types';
import note from "../../image/images/Vector-1.png";

const elementName = (index:number,name:string) => {
    return `notes[${index}].${name}`
}

export const Notes : React.FunctionComponent<{
    aptDetail: DoctorAppointmentDetail | undefined
}> = (props)=> {

    const {control,handleSubmit} = useForm();
    const [response,setResponse] = React.useState("");

    const { fields, append,remove } = useFieldArray({
        control,
        name: "notes"
    });

    // console.log("props.params.id",props.params);

    
      const onsubmit = (data :any) => {
        console.log("any",data);
        // axios.post<any>('http://' + process.env.REACT_APP_API_URL + '/api/add-note?id=2',data.notes,{withCredentials: false})
        // .then((response) => {
        //     console.log(response.data);
        //     setResponse("Operation Completed...!");
        // }).catch((error) => {
        //     console.log(error);
        //     setResponse(error.toString());
        // });
    }

    useEffect(()=>{
        append({})
    },[])

    return (
            props.aptDetail ?
                <div className='page-padding'>
                    <form onSubmit={handleSubmit(onsubmit)}>
                        <Heading heading='Add notes' url={`/appointments/${props.aptDetail.id}`} />
                        <div className="section-divider"></div>
                        <PersonalInfo  patient={props.aptDetail.Patient}/>
                        <SubHeading iconPath={note} tittle='Notes'/>
                        <div className="we-have-sent-you-the-otp-to-83009-88763">
                            <div className="section-profile-personal-info-form w-form">
                                {   
                                fields.map((item, index) => (
                                    <div key={item.id} className='margin-top-30 bg-shadow'>
                                        <div className="form-stacked">
                                            <div  className="form-stacked-items">
                                                <label className="field-label"># Notes  {index+1}</label>
                                            </div>
                                            <div className="form-stacked-items">
                                                {
                                                    index !== 0 ?
                                                        <div className="p-btn" onClick={()=>{remove(index)}}>delete</div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                        <InputComponent control={control} elementName="report" index={index} type={"file"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">GENERAL :</div>
                                            <InputComponent control={control} elementName="general" index={index} type={"text"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">COMPLAINTS :</div>
                                            <InputComponent control={control} elementName="complaints" index={index} type={"text"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">STRESSOR :</div>
                                            <InputComponent control={control} elementName="stressor" index={index} type={"text"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">HISTORY :</div>
                                            <InputComponent control={control} elementName="history" index={index} type={"text"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">FAMILY HISTORY :</div>
                                            <InputComponent control={control} elementName="family_history" index={index} type={"text"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">MENTAL STATUS EXAM :</div>
                                            <InputComponent control={control} elementName="mental_status_exam" index={index} type={"text"}/>
                                        </div>
                                        <div>
                                            <div className="field-label">ADVICE :</div>
                                            <InputComponent control={control} elementName="advice" index={index} type={"text"}/>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className="we-have-sent-you-the-otp-to-83009-88763">
                            <div onClick={()=>{append({})}} className="section_button-wrapper secondary-btn w-button">
                                Add more
                            </div>
                        </div>
                        <div className='section-divider'></div>
                        <div className='margin-top-30'>
                            <button type='submit' className="section_button-wrapper primary-btn w-button">
                                Confirm and send to the patient
                            </button>
                        </div>
                    </form>
                </div>
            :
                <div>Appoitment details can't be found</div>
    );
}


const InputComponent : React.FunctionComponent<{
    control : Control<Record<string, any>>,
    type:string,
    index:number,
    elementName:string,
    options?:Option[]
   }> = (props) => {
        switch (props.type) {
            case "select":
              return <Controller
              control={props.control}
              name={elementName(props.index,props.elementName)}
              render={({value,onChange }) => (
                <select className="text-field-border w-input" onChange={onChange} value={value}>
                    <option value="" selected disabled>Please select</option>
                    {props.options?.map((ed,index)=>{
                        return <option value={ed.value}>{ed.label}</option>
                    })}
                </select>
              )}
              />
            case "file":
                return <Controller
                control={props.control}
                name={elementName(props.index,props.elementName)}
                render={({value,onChange }) => (
                    <input type={props.type}
                    onChange={onChange}
                    value={value}
                    multiple
                    />
                )}
                />
            default:
              return <Controller
              control={props.control}
              name={elementName(props.index,props.elementName)}
              render={({value,onChange }) => (
                  <input className="text-field-border w-input" type={props.type}
                  onChange={onChange}
                  value={value}
                  />
              )}
              />
        }
 }
