import { useContext, useEffect, useState } from "react";
import { Switch,BrowserRouter ,Route,Redirect } from "react-router-dom";
// import Login from "./auth/Login";
import Home from "./Home";
import Login from "./Pages/Auth/login";
import DoctorLogin from "./Pages/Auth/DoctorLogin";
import Signup from "./Pages/Auth/signup";
import DoctorSignup from "./Pages/Auth/DoctorSignup";
import {DoctorRouter} from "./WizardForm/DoctorRouter";
// import ForgotPassword from "./ForgotPassword";
import { ResourceContextProvider, ResourceContext } from "./Context/ContextAPI";
import { StateMachineProvider, createStore } from "little-state-machine";


const getCookie = (name: string) => {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts?.pop()?.split(";").shift();
};


createStore({},{});


const App = () => {

  // const useContxtData = useContext(ResourceContext);

  const [hasLoggedIn, setHasLoggedIn] = useState<boolean>(
    window.localStorage.getItem('rh-token') ? true : false
    // getCookie("p_token") ? true : ( getCookie("d_token") ? true : false)
  );

  return (
    <StateMachineProvider>
      <ResourceContextProvider>
        <div className="Apdoctorp">
          <BrowserRouter>
            <Switch>
              <Route path="/signup">
                <Signup/>
              </Route>
              <Route path="/doctor-signup">
                <DoctorSignup/>
              </Route>
              <Route path="/login">
                <Login/>
              </Route>
              <Route path="/doctor-login">
                <DoctorLogin/>
              </Route>
              <Route path="/">
                {hasLoggedIn ? <Home/> : <Redirect to="/login" />}
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
      </ResourceContextProvider>
    </StateMachineProvider>
  );
};

export default App;
