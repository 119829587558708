import React from 'react';
import { FieldValues,Control,Controller,useForm,
    UseFormMethods,FieldError,DeepMap } from 'react-hook-form';
import {InputComponent,fieldConstruct,InputErrorDesign} from "../../WizardForm/BasicDetail";
import axios from 'axios';
import logo from "../../../image/images/Wordmark-Black-1.png";

const Login = () => {
    const method = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data:any) => {
        axios.post<any>(process.env.REACT_APP_API_URL + '/api/doctor-signin',data,{
            withCredentials: true
        })
        .then((response) => {
            console.log(response.data.user);
            window.localStorage.setItem('rh-token', response.data.token);
            window.localStorage.setItem('rh-token-type', 'doctor');
            setResponse("Operation Completed...!");
            window.location.href = "/appointments";
        }).catch((error) => {
            console.log(error);
            setResponse(error.message.toString());
        });
    }

    return(
        <div className="page-wrapper">
            <div className="main-wrapper">
            <div className="main-wrapper_mobile">
                <div className="login-nav">
                    <img src={logo} loading="lazy" alt="" className="image-8"/>
                </div>
                <div className="page-padding">
                    <div className="section-login-screen">
                        <div className="section-login-screen">
                            <div className="section-login-wrapper">
                            <h1 className="text-medium">Login</h1>
                            </div>
                            <div className="margin-top-40">
                            <form onSubmit={method.handleSubmit(onsubmit)}>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("text","email","email",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div>
                                    <InputComponent method={method} field={fieldConstruct("password","password","password",true)} value={""} className='btn-shadow'/>
                                </div>
                                <div style={{textAlign:'center',paddingTop:'1rem'}}>
                                    <input className="section_button-wrapper inactive-btn w-button" type="submit" value="Sign In" />
                                </div>
                                <div style={{textAlign:'center',paddingTop:'1rem'}}>
                                    {response}
                                </div>
                            </form>
                            </div>
                            <div className="margin-top-40">
                            <div className="div-block-4">
                                <a href="/doctor-signup" className="link-block-4 w-inline-block">
                                <h1 className="text-size-regular text-weight-regular"><span className="text-span-2">Don’t have an account?</span>
                                <span className="text-span-3"> Sign up</span></h1>
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-login-footer">
                    <p className="paragraph-2">By creating an account, you agree to our
                    <span className="is-orange">T&amp;Cs</span></p>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Login;
