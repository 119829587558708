import React from 'react';
import { DoctorDetail,
    PatientDetail,
    PersonalInfo,
    Option,
    SpecializationInfo,
    BankInfo } from '../../../@types/Types';
import { useForm,Control,Controller } from 'react-hook-form';
import axios from 'axios';
import {ProfileHeader} from "../../Elements/Common";
import {ShippingIcon,PersonalIcon,BankIcon, SpecializationIcon} from "../../../image/Icons";
import { NavLink } from "react-router-dom";
import personal from "../../../image/images/Group-3-1.png";
import shipping from "../../../image/images/Vector-15-1.png";

import MultiDropDown from "../../Elements/MultiDropDown";

const gender : Option[] = [{label:"Male",value:"Male"},{label:"Female",value:"Female"},{label:"Intersex",value:"Intersex"}]

const language : Option[] = [{label:"Tamil",value:"Tamil"},{label:"Hindhi",value:"Hindhi"},{label:"English",value:"English"}];

export const PatientPersonalDetail : React.FunctionComponent<{
    userDetail: PatientDetail ,
}> = (props) => {

    const {control,handleSubmit} = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data : any) => {
        console.log("submit datas",data);
        axios.put<any>('http://' + process.env.REACT_APP_API_URL + '/api/patient-profile',data,{
            withCredentials: true,
            headers:{
                'x-rockethealth-auth': window.localStorage.getItem('rh-token')
            }
        })
        .then((response) => {
            console.log(response.data);
            setResponse("Operation Completed...!");
            window.location.href = `/profile`;
        }).catch((error) => {
            console.log(error);
            setResponse(error.toString());
        });
    }

    return (
        <div className="main-wrapper_mobile">
            <div className='page-padding'>
                <ProfileHeader tittle={"Personal Info"} icon={personal} />
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div>
                        <label className="field-label">FIRST NAME:</label>
                        <InputComponent control={control} type={"text"} elementName={"first_name"}
                        value={props.userDetail.first_name}/>
                    </div>
                    <div>
                        <label className="field-label">LAST NAME:</label>
                        <InputComponent control={control} type={"text"} elementName={"last_name"}
                        value={props.userDetail.last_name}/>
                    </div>
                    <div>
                        <label className="field-label">AGE:</label>
                        <InputComponent control={control} type={"number"} elementName={"age"}
                        value={props.userDetail.age != null ? props.userDetail.age.toString() : "0" }/>
                    </div>
                    <div>
                        <label className="field-label">GENDER:</label>
                        <InputComponent control={control} type={"select"} elementName={"gender"} options={gender} value={props.userDetail.gender}/>
                    </div>
                    <div>
                        <label className="field-label">EMAIL:</label>
                        <InputComponent control={control} type={"text"} elementName={"email"}
                        value={props.userDetail.email}/>
                    </div>
                    <div>
                        <label className="field-label">PHONE NUMBER:</label>
                        <InputComponent control={control} type={"text"} elementName={"phone_number"}
                        value={props.userDetail.phone_number}/>
                    </div>
                    <div>
                        <label className="field-label">EMERGENCY CONTACT:</label>
                        <InputComponent control={control} type={"text"} elementName={"emergency_contact"}
                        value={props.userDetail.emergency_contact}/>
                    </div>
                    <ActionBtn/>
                </form>
                <div>{response}</div>
            </div>
        </div>
    );
}

export const PatientShippingAddress : React.FunctionComponent<{
    userDetail: PatientDetail,
}> = (props) => {

    const {control,handleSubmit} = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data : any) => {
        console.log("submit datas",data);
        axios.put<any>(process.env.REACT_APP_API_URL + '/api/patient-address',data,{
            withCredentials: true,
            headers:{
                'x-rockethealth-auth': window.localStorage.getItem('rh-token')
            }
        })
        .then((response) => {
            console.log(response.data);
            setResponse("Operation Completed...!");
            window.location.href = `/profile`;
        }).catch((error) => {
            console.log(error);
            setResponse(error.toString());
        });
    }

    return (
        <div className="main-wrapper_mobile">
            <div className='page-padding'>
                <ProfileHeader tittle={"Shipping Address"} icon={shipping}/>
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div>
                        <label className="field-label">ADDRESS LINE 2:</label>
                        <InputComponent control={control} type={"text"} elementName={"address_line_2"}
                        value={props.userDetail.address_line_2}/>
                    </div>
                    <div>
                        <label className="field-label">CITY:</label>
                        <InputComponent control={control} type={"text"} elementName={"city"}
                        value={props.userDetail.city}/>
                    </div>
                    <div>
                        <label className="field-label">STATE:</label>
                        <InputComponent control={control} type={"text"} elementName={"state"}
                        value={props.userDetail.state}/>
                    </div>
                    <div>
                        <label className="field-label">PIN CODE:</label>
                        <InputComponent control={control} type={"text"} elementName={"pin_code"}
                        value={props.userDetail.pincode}/>
                    </div>
                   <ActionBtn/>
                </form>
                <div>{response}</div>
            </div>
        </div>
    );
}

const ActionBtn = () => {
    return (
        <div className='div-block-3'>
            <NavLink className="section-appointment_button secondary-btn w-button" to={"/profile"}>
                Cancel
            </NavLink>
            <button type="submit" className="section-appointment_button primary-btn w-button" value="Submit">
                Save Changes
            </button>
        </div>
    );
}

export const DoctorBankDetail : React.FunctionComponent<{
    userDetail: DoctorDetail,
}> = (props) => {

    const {control,handleSubmit} = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data : BankInfo) => {
        console.log("submit datas",data);
        axios.put<any>('http://' + process.env.REACT_APP_API_URL + '/api/doctor-bankinfo',data,{
            withCredentials: true,
            headers:{
                'x-rockethealth-auth': window.localStorage.getItem('rh-token')
            }
        })
        .then((response) => {
            console.log(response.data);
            setResponse("Operation Completed...!");
            window.location.href = `/profile`;
        }).catch((error) => {
            console.log(error);
            setResponse(error.toString());
        });
    }

    return (
        <div className="main-wrapper_mobile">
            <div className='page-padding'>
                <ProfileHeader tittle={"Bank Details"} icon={shipping}/>
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div>
                        <label className="field-label">BANK ACCOUNT NUMBER:</label>
                        <InputComponent control={control} type={"text"} elementName={"bank_account_number"}
                        value={props.userDetail.bank_account_number}/>
                    </div>
                    <div>
                        <label className="field-label">IFSC CODE:</label>
                        <InputComponent control={control} type={"text"} elementName={"ifsc"}
                        value={props.userDetail.ifsc}/>
                    </div>
                    <div>
                        <label className="field-label">UPI ID:</label>
                        <InputComponent control={control} type={"text"} elementName={"upi_id"}
                        value={props.userDetail.upi_id}/>
                    </div>
                    <ActionBtn/>
                </form>
                <div>{response}</div>
            </div>
        </div>
    );
}

export const DoctorSpecialisationDetail : React.FunctionComponent<{
    userDetail: DoctorDetail,
}> = (props) => {

    const {control,handleSubmit} = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data : SpecializationInfo) => {
        console.log("submit datas",data);
        axios.put<any>('http://' + process.env.REACT_APP_API_URL + '/api/doctor-specialisation',data,{
            withCredentials: true,
            headers:{
                'x-rockethealth-auth': window.localStorage.getItem('rh-token')
            }
        })
        .then((response) => {
            console.log(response.data);
            setResponse("Operation Completed...!");
            window.location.href = `/profile`;
        }).catch((error) => {
            console.log(error);
            setResponse(error.toString());
        });
    }

    return (
        <div className="main-wrapper_mobile">
            <div className='page-padding'>
                <ProfileHeader tittle={"Specialisation Details"} icon={shipping}/>
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div>
                        <label className="field-label">SPECIALIZATION:</label>
                        <InputComponent control={control} type={"text"} elementName={"specialization"}
                        value={props.userDetail.specialization}/>
                    </div>
                    <div>
                        <label className="field-label">DEGREE:</label>
                        <InputComponent control={control} type={"text"} elementName={"degree"}
                        value={props.userDetail.degree}/>
                    </div>
                    <div>
                        <label className="field-label">REGISTRATION NUMBER:</label>
                        <InputComponent control={control} type={"text"} elementName={"registration_number"}
                        value={props.userDetail.registration_number}/>
                    </div>
                    <ActionBtn/>
                </form>
                <div>{response}</div>
            </div>
        </div>
    );
}

export const DoctorPersonalDetail : React.FunctionComponent<{
    userDetail: DoctorDetail,
}> = (props) => {

    const {control,handleSubmit} = useForm();
    const [response,setResponse] = React.useState("");

    const onsubmit = (data : PersonalInfo) => {
        console.log("submit datas",data);
        var afterData = {
            photo             : data.photo,
            first_name        : data.first_name,
            last_name         : data.last_name,
            prefix            : data.prefix,
            email             : data.email,
            phone_number      : data.phone_number,
            city              : data.city,
            languages         : data.languages ? data.languages.map((ed:Option) => ed.value) : [],
            gender            : data.gender,
            dob               : data.dob,
        }
        console.log("after submit datas",afterData);
        axios.put<any>('http://' + process.env.REACT_APP_API_URL + '/api/doctor-personal',afterData,{
            withCredentials: true,
            headers:{
                'x-rockethealth-auth': window.localStorage.getItem('rh-token')
            }
        })
        .then((response) => {
            console.log(response.data);
            setResponse("Operation Completed...!");
            window.location.href = `/profile`;
        }).catch((error) => {
            console.log(error);
            setResponse(error.toString());
        });
    }

    return (
        <div className="main-wrapper_mobile">
            <div className='page-padding'>
                <ProfileHeader tittle={"Personal Info"} icon={personal} />
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div>
                        <label className="field-label">PHOTO:</label>
                        <InputComponent control={control} type={"text"} elementName={"photo"}
                        value={props.userDetail.photo}/>
                    </div>
                    <div>
                        <label className="field-label">FIRST NAME:</label>
                        <InputComponent control={control} type={"text"} elementName={"first_name"}
                        value={props.userDetail.first_name}/>
                    </div>
                    <div>
                        <label className="field-label">LAST NAME:</label>
                        <InputComponent control={control} type={"text"} elementName={"last_name"}
                        value={props.userDetail.last_name}/>
                    </div>
                    <div>
                        <label className="field-label">PREFIX:</label>
                        <InputComponent control={control} type={"text"} elementName={"prefix"}
                        value={props.userDetail.prefix}/>
                    </div>
                    <div>
                        <label className="field-label">GENDER:</label>
                        <InputComponent control={control} type={"select"} elementName={"gender"} options={gender} value={props.userDetail.gender}/>
                    </div>
                    <div>
                        <label className="field-label">DATE OF BIRTH:</label>
                        <InputComponent control={control} type={"date"} elementName={"dob"}
                        value={props.userDetail.dob.toString()}/>
                    </div>
                    <div>
                        <label className="field-label">EMAIL:</label>
                        <InputComponent control={control} type={"text"} elementName={"email"}
                        value={props.userDetail.email}/>
                    </div>
                    <div>
                        <label className="field-label">PHONE NUMBER:</label>
                        <InputComponent control={control} type={"text"} elementName={"phone_number"}
                        value={props.userDetail.phone_number}/>
                    </div>
                    <div>
                        <label className="field-label">CITY:</label>
                        <InputComponent control={control} type={"text"} elementName={"city"}
                        value={props.userDetail.city}/>
                    </div>
                    <div>
                        <label className="field-label">LANGUAGES:</label>
                        <MultiDropDown name="languages" control={control} options={language}
                        value={props.userDetail.languages ? props.userDetail.languages : []} />
                    </div>
                    <ActionBtn/>
                </form>
                <div>{response}</div>
            </div>
        </div>
    );
}


const InputComponent : React.FunctionComponent<{
    control : Control<Record<string, any>>,
    type:string,
    elementName:string,
    options?:Option[]
    value:string
   }> = (props) => {
        switch (props.type) {
            case "select":
              return <Controller
              control={props.control}
              name={props.elementName}
              render={({value,onChange }) => (
                <select className="text-field-border w-input" onChange={onChange} value={value}>
                    {props.options?.map((ed,index)=>{
                        return <option value={ed.value}>{ed.label}</option>
                    })}

                </select>
              )}
              defaultValue={props.value}
              />
            default:
              return <Controller
              control={props.control}
              name={props.elementName}
              render={({value,onChange }) => (
                  <input className="text-field-border w-input" type={props.type}
                  onChange={onChange}
                  value={value}
                  />
              )}
              defaultValue={props.value}
              />
        }
 }
