import React from 'react';
import { ComingSoon,Footer } from '../Elements/Common';


const Message = () => {
  return (
    <>
      <ComingSoon/>
      <Footer/>
    </>
  );
}

export default Message;