import React,{useEffect} from 'react';
import { useForm,useFieldArray,Controller,Control } from 'react-hook-form'
import {Goback,Med} from "../../image/Icons";
import { Medicines,
         Option,
         PatientDetail} from '../../@types/Types';
import axios from 'axios';
import MultiDropDown from "../Elements/MultiDropDown";
import { NavLink,useHistory } from "react-router-dom";
import { DoctorAppointmentDetail } from '../../@types/Types';
import medicen from "../../image/images/medication_black_24dp-1.png";
import {Heading,PersonalInfo,SubHeading} from "../Elements/Common";
enum Days {
    Monday ='Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday ='Saturday',
    Sunday ='Sunday'
}

enum Frequencys {
    BLD = '1-1-1',
    LD  = '0-1-1',
    BD  = '1-0-1',
    BL  = '1-1-0'
}
const elementName = (index:number,name:string) => {
    return `pres[${index}].${name}`
}

const freq = Object.values(Frequencys).map((ed) => {
    return { value : ed,label : ed }
  });

const day = Object.keys(Days).map((ed) => {
  return { value : ed,label : ed }
})

export const Prescription : React.FunctionComponent<{
    aptDetail: DoctorAppointmentDetail | undefined
  }> = (props) => {

    const {control,handleSubmit} = useForm();
    let history = useHistory();
    const [response,setResponse] = React.useState("");

    const { fields, append,remove } = useFieldArray({
        control,
        name: "pres"
    });


    const onsubmit = (datas :Medicines) => {
        console.log("data",datas.pres);
        const afterData = datas.pres.map((data)=>{
            return {
                medicine     :   data.medicine,
                frequency    :   data.frequency,
                days         :   data.days.map((ed:any)=>{return ed.value}),
                instructions :   data.instructions,
                dose         :   data.dose
            }
        })
        console.log("after submit datas",afterData);
        if(typeof props.aptDetail != "undefined"){
            axios.post<any>(process.env.REACT_APP_API_URL + '/api/add-prescription?id='+props.aptDetail.id,{
                pres : afterData
            },{
                withCredentials: false,
                headers:{
                    'x-rockethealth-auth': window.localStorage.getItem('rh-token')
                }
            })
            .then((response) => {
                console.log(response.data);
                setResponse("Operation Completed...!");
            }).catch((error) => {
                console.log(error);
                setResponse(error.toString());
            });
        }
    }

    useEffect(()=>{
        append({})
    },[]);

    return (
        props.aptDetail ?
            <div className='page-padding'>
                <form onSubmit={handleSubmit(onsubmit)}>
                <Heading heading='Add prescription' url={`/appointments/${props.aptDetail.id}`} />
                <div className="section-divider"></div>
                <PersonalInfo  patient={props.aptDetail.Patient}/>
                <SubHeading iconPath={medicen} tittle='Add Prescription'/>
                <div className="we-have-sent-you-the-otp-to-83009-88763">
                    <div className="section-profile-personal-info-form w-form">
                        {   fields.map((item, index) => (
                            <div key={item.id} className='margin-top-30 bg-shadow'>
                                <div className="form-stacked">
                                    <div  className="form-stacked-items">
                                        <label className="field-label">#MEDICINE {index+1}</label>
                                    </div>
                                    <div className="form-stacked-items">
                                    {
                                        index !== 0 ?
                                            <img src={medicen} onClick={()=>{remove(index)}} style={{float:'right'}}></img>
                                        :
                                            <></>
                                    }
                                    </div>
                                </div>
                                <InputComponent control={control} elementName="medicine" index={index} type={"text"}/>
                                <div className="form-stacked">
                                    <div  className="form-stacked-items">
                                        <label  className="field-label">Frequency</label>
                                        <InputComponent control={control} elementName="frequency" index={index} type={"select"} options={freq}/>
                                    </div>
                                    <div className="form-stacked-items">
                                        <label className="field-label">Dose</label>
                                        <InputComponent control={control} elementName="dose" index={index} type={"select"} options={freq}/>
                                    </div>
                                </div>
                                <label className="field-label">Days</label>
                                <MultiDropDown control={control} name="days" index={index} options={day} value={[]} parentName={"pres"}/>
                                <label className="field-label">Instructions</label>
                                <InputComponent control={control} elementName="instructions" index={index} type={"text"}
                                />
                            </div>
                            ))
                        }
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
                <div className="we-have-sent-you-the-otp-to-83009-88763">
                    <div onClick={()=>{append({})}} className="section_button-wrapper secondary-btn w-button">
                        Add more
                    </div>
                </div>
                <div className='section-divider'></div>
                <div className='margin-top-30'>
                    <button type='submit' className="section_button-wrapper primary-btn w-button">
                        Confirm and send to the patient
                    </button>
                </div>
                </form>
            </div>
        :
            <div>Appoitment details can't be found</div>
    );
}



const InputComponent : React.FunctionComponent<{
    control : Control<Record<string, any>>,
    type:string,
    index:number,
    elementName:string,
    options?:Option[]
   }> = (props) => {
        switch (props.type) {
            case "select":
              return <Controller
              control={props.control}
              name={elementName(props.index,props.elementName)}
              render={({value,onChange }) => (
                <select className="text-field-border w-input" onChange={onChange} value={value}>
                    <option value="" selected disabled>Please select</option>
                    {props.options?.map((ed,index)=>{
                        return <option value={ed.value}>{ed.label}</option>
                    })}
                </select>
              )}
              />
            default:
              return <Controller
              control={props.control}
              name={elementName(props.index,props.elementName)}
              render={({value,onChange }) => (
                  <input className="text-field-border w-input" type={props.type}
                  onChange={onChange}
                  value={value}
                  />
              )}
              />
        }
 }
