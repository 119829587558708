import React from "react";
import { PatientAppointmentDetail } from '../../../@types/Types';
// import {Goback,CalenderIcon} from "../../../image/Icons";
// import chat from "../../../image/images/chat_black_24dp-4-1-1.png";
// import calender from "../../../image/images/event_black_24dp-4-4-1.png";
// import mdeical from "../../../image/images/medication_black_24dp-1-2-1.png";
// import description from "../../../image/images/description_black_24dp-6-2-1.png";
// import help from "../../../image/images/help_black_24dp-3-2-1.png";
// import schedule from "../../../image/images/schedule_black_24dp-2-1.png";
import { Heading,PatientAppointDocContain } from "../../Elements/Common";
import prescripIcon from "../../../image/images/Vector-17-1.png";
import megaPres from "../../../image/images/34238bac87190c4ee62c315752d455bd-1.png";
import moment from "moment";
import notes from "../../../image/images/collections_black_24dp-1-2.png";
import pdf from "../../../image/images/Vector-19-1.png";
import noteTick from "../../../image/images/Group-16735-1.png";
import wrkbook from "../../../image/images/menu_book_black_24dp-1-1-1.png"
import wrkbookIcon from "../../../image/images/Frame-17093-3-1.png"
import report from "../../../image/images/Vector-18-1.png";

const normal = {fontWeight:"normal",fontSize:"14px"};

const AptviewDetail : React.FunctionComponent<{
    aptDetail: PatientAppointmentDetail | undefined
  }> = (props) => {
    
    return (
        props.aptDetail ?
            <div className="section-orders-wrapper">
              <div className="page-padding">
                <Heading url={"/appointments"} heading="Appointment details" />
                <div className="section-divider"/>
                <PatientAppointDocContain
                  link={props.aptDetail.appointment_link}
                  status={props.aptDetail.status}
                  a_id={props.aptDetail.appoinment_number}
                  f_name={( props.aptDetail.Doctor.prefix === null || props.aptDetail.Doctor.prefix.length <= 0 ? "" : props.aptDetail.Doctor.prefix + '. ')
                  + props.aptDetail.Doctor.first_name}
                  l_name={props.aptDetail.Doctor.last_name != null ? props.aptDetail.Doctor.last_name : ""}
                  a_datetime={props.aptDetail.appointment_datetime} />
                <PrescriptionElem a_time={props.aptDetail.appointment_datetime}/>
                <ReportsElem/>
                <WorkBooks/>
              </div>
            </div>
        :
            <>appointment details not getting</>
        
    );
}

const WorkBooks = () => {
  return (
    <div className="section-appointment_list-item">
      <div className="w-layout-grid section-appointment_layout">
        <img src={wrkbook} loading="lazy" id="w-node-b85c2292-5f71-3e1c-9428-8ea63671c22f-c8396e66" sizes="50px" 
        // srcset="../images/menu_book_black_24dp-1-1-1-p-500.png 500w, ../images/menu_book_black_24dp-1-1-1-p-800.png 800w, ../images/menu_book_black_24dp-1-1-1.png 1359w" 
        alt="" className="icon-size-medium"/>
        <div id="w-node-b85c2292-5f71-3e1c-9428-8ea63671c230-c8396e66" className="section-appointment_list-item-details">
          <div className="margin-top-10">
            <h1 className="appointment-details">Workbooks</h1>
          </div>
        </div>
      </div>
      <div className="we-have-sent-you-the-otp-to-83009-88763">
        <div className="w-layout-grid section-appointment_layout">
          <img src={wrkbookIcon} loading="lazy" id="w-node-_03c7635e-bfb8-1c25-3130-964ca6fbd4f0-c8396e66" alt="" className="item-image-copy"/>
          <div id="w-node-_03c7635e-bfb8-1c25-3130-964ca6fbd4f1-c8396e66" className="section-appointment_list-item-details">
            <div className="margin-top-10">
              <h1 className="appointment-details">Mindfulness</h1>
            </div>
            <div className="text_appointment-drname">2.5Mb</div>
            <div className="we-have-sent-you-the-otp-to-83009-88763">
              <a href="#" className="section-appointment_button-copy secondary-btn w-button">Download</a>
            </div>
          </div>
        </div>
      </div>
      <div className="margin-top-30">
        <a href="#" target="_blank" className="section_button-wrapper primary-btn w-button">Book again</a>
      </div>
    </div>
  );
}

const ReportsElem : React.FunctionComponent<{
}> = (props) => {
  return (
    <div className="section-appointment_list-item">
      <div className="w-layout-grid section-appointment_layout">
        <img src={report} loading="lazy" alt="" className="icon-size-medium"/>
        <div id="w-node-_682a36de-6f92-cfb9-ee52-1aeeb95778c6-c8396e66" className="section-appointment_list-item-details">
          <div className="margin-top-10">
            <h1 className="appointment-details">Add your report (If needed)</h1>
          </div>
        </div>
      </div>
      <div className="margin-top-30">
        <div className="section-upload">
          <div className="section-upload-wrapper">
            <img src={notes} loading="lazy" alt="" className="image-2"/>
            <div className="we-have-sent-you-the-otp-to-83009-88763">
              <h1 className="appointment-details">Upload a new photo/file</h1>
            </div>
            <div className="margin-top-10">
              <div className="text_appointment-drname">JPEG &amp; PNG, PDF format</div>
            </div>
          </div>
        </div>
      </div>
      <div className="margin-top-30">
        <div className="section-report_list-item">
          <div className="w-layout-grid section-appointment_layout-copy">
            <img src={pdf} loading="lazy" id="w-node-_7fcdd204-64d3-4fb7-9803-f1b40cd4a42c-c8396e66" alt="" className="item-icon"/>
            <div id="w-node-_7fcdd204-64d3-4fb7-9803-f1b40cd4a42d-c8396e66" className="section-appointment_list-item-details">
              <div className="margin-top-10">
                <h1 className="appointment-details">Your report.pdf</h1>
              </div>
              <div className="margin-top-10">
                <div className="text_report-uploaded">100% uploaded</div>
              </div>
            </div>
            <img src={noteTick} loading="lazy" id="w-node-d8fc041e-5196-adc4-5890-7f5940b87673-c8396e66" alt="" className="icon-size-medium margin-top-10"/>
          </div>
        </div>
      </div>
    </div>
  );
}

const PrescriptionElem : React.FunctionComponent<{
  a_time : Date
}> = (props) => {
  return (
    <div className="section-appointment_list-item">
      <div className="w-layout-grid section-appointment_layout">
        <img src={prescripIcon} loading="lazy" alt="" className="icon-size-medium margin-top-10"/>
        <div className="section-appointment_list-item-details">
          <div className="margin-top-10">
            <h1 className="appointment-details">Prescription</h1>
          </div>
          <div className="margin-top-10" >
            <div className="text_appointment-drname" style={normal}>Added at {moment(props.a_time).format("LLL")}</div>
          </div>
        </div>
        <div id="w-node-_607148af-a3da-0caa-f2c3-e5ff357de793-c8396e66" className="section-appointment-details">
          <div id="w-node-_607148af-a3da-0caa-f2c3-e5ff357de794-c8396e66" className="section-appointment-details_list-item-details">
            <img src={megaPres} loading="lazy" alt="" className="image"/>
          </div>
        </div>
      </div>
      <div className="margin-top-30">
        <div className="section-btn-wrapper">
          <a href="#" className="section-appointment_button secondary-btn w-button">Download</a>
          <a href="https://wa.me/+918971768946?text=Hey%2C+I+need+to+place+an+order+for+my+medicines" target="_blank" className="section-appointment_button primary-btn w-button">
            Buy medicine
          </a>
        </div>
      </div>
    </div>
  );
}

export default AptviewDetail;