/* eslint-disable @typescript-eslint/no-unused-vars */
import { url } from 'inspector';
import React from 'react';
import { NavLink,useHistory } from "react-router-dom";
import {Apt_notFound} from "../../image/Icons";
import vedioIcon from "../../image/images/Frame-17093-1.png";
import vedio from "../../image/images/Vector-16-1.png";
import calenderIcon from "../../image/images/event_black_24dp-4-4-1.png";
import moment from 'moment';
import goback from "../../image/images/Group-16737-2-1.png";
import whatsapp from "../../image/images/whatsapp-3-1-3-1.png";
import phone from "../../image/images/Group-16734-1.png";
import msg from "../../image/images/Group-16718-1-1.png";

import pic from "../../image/images/Frame-17092-1-1.png";
import annonce from "../../image/images/Group-16713-2-1.png";
import leaf from "../../image/images/branch-with-leaves-black-shape.png";
import { PatientDetail} from '../../@types/Types';
import chat from "../../image/images/chat_black_24dp-4-1-1.png";

export const ReadComp : React.FunctionComponent<{name:string,value:any}> = (props) => {
    return (

            <>
                <label className="field-label">{props.name}</label>
                <div className="text-field-border w-input">{nullChecker(props.value)}</div>
            </>
    );
}

const normal = {fontWeight:"normal",fontSize:"14px"};

export const GrpReadComp : React.FunctionComponent<{name1:string,value1:any,name2:string,value2:any}> = (props) => {
    return (
        <div className="form-stacked">
            <div  className="form-stacked-items">
                <label className="field-label">{props.name1}</label>
                <div className="text-field-border w-input">{nullChecker(props.value1)}</div>
            </div>
            <div className="form-stacked-items">
                <label className="field-label">{props.name2}</label>
                <div className="text-field-border w-input">{nullChecker(props.value2)}</div>
            </div>
        </div>
    );
}

export const SubHeading : React.FunctionComponent<{
    iconPath    :   string,
    tittle      :   string
}> = (props) => {
    return (
        <div className="we-have-sent-you-the-otp-to-83009-88763">
            <div className="section-profile-personal-info">
                <div className="personal-info-wrapper">
                    <img loading="lazy" src={props.iconPath} alt=""/>
                    <h1 className="text-heading">{props.tittle}</h1>
                </div>
            </div>
        </div>
    );
}

export const Heading : React.FunctionComponent<{
    url : string,
    heading:string,
   }> = (props) => {
    return (
        <div className="section-profile-personal-info">
            <div className="personal-info-wrapper">
                <div onClick={() => {
                    window.location.href = props.url;
                }} className="w-inline-block">
                    <img src={goback} loading="lazy" alt="" className="icon-size-back"/>
                </div>
                <h1 className="text-heading">
                    {props.heading}
                </h1>
            </div>
        </div>
    );
}

const nullChecker = (value :any) => {
    return value != null ? value : ""
}

export const PersonalInfo : React.FunctionComponent<{
    patient : PatientDetail
   }> = (props) => {
    return(
        <div className="section-profile-personal-info-form">
            <div className="we-have-sent-you-the-otp-to-83009-88763">
                <div className="section-appointment-detail_list">
                    <div>
                        <div className="w-layout-grid section-appointment_layout">
                            <div id="w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a29-59396e63" className="section-appointment-details-copy">
                                <div id="w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a2a-59396e63"  className="section-checkins_list-item-details">
                                    <div className="list-item-details_dates">
                                        <div className="is-underlined">PATIENT INFO</div>
                                    </div>
                                    <div className="text-heading">
                                        {props.patient.first_name + " " + nullChecker(props.patient.last_name)}
                                    </div>
                                    <div className="list-item-details_dates">
                                        <div className="text_checkins-date">
                                            { nullChecker(props.patient.gender) +  nullChecker(props.patient.age).length > 0 ? " , " + nullChecker(props.patient.age) + " years" : ""}
                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a33-59396e63" className="section_button-wrapper secondary-btn">
                                    <img src={chat} loading="lazy" width="25" alt="" className="image-3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-divider"></div>
                </div>
            </div>
        </div>
    )
}

export const ProfileHeader : React.FunctionComponent<{tittle:string,icon:string,link?:string}> = (props) => {
    return (
        // <div className="df-fdr j-csb pd-5rem">
        //     <div className="a-sc">
        //         {props.icon}
        //     </div>
        //     <div className="f-w700 f-s24 a-sc">
        //         {props.tittle}
        //     </div>
        //     {
        //         props.link ?
        //             <NavLink to={props.link}>
        //                 <div className="p-btn ">
        //                     Edit
        //                 </div>
        //             </NavLink>
        //         :
        //            <div></div>
        //     }

        // </div>
        <div className="section-profile-personal-info">
            <div id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b2-ad32b5ad" className="personal-info-wrapper">
                <img id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b3-ad32b5ad" loading="lazy" src={props.icon} alt="" className="icon-size-small"/>
                <h1 id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b4-ad32b5ad" className="text-heading">
                    {props.tittle}
                </h1>
            </div>
            {
                props.link ?
                    <NavLink to={props.link} id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b6-ad32b5ad" className="section-profile-personal-info-btn">
                        <a  className="section-primary_button secondary-btn w-button">Edit</a>
                    </NavLink>
                :
                    <></>
            }
        </div>
    );
}


export const AppoitContain : React.FunctionComponent<{
    f_name : string,
    l_name : string,
    a_datetime : Date,
    appt: any,
    a_id : number,
    index : number,
    doctor : boolean
}> = (props) => {
    return (
        <div className="w-layout-grid section-appointment_layout-copy">
            <img id="w-node-_67537186-6b88-7cde-1cda-06daae5a7737-45396e68" loading="lazy" src={vedioIcon} alt="" className="icon-image-copy"/>
            <div id="w-node-_67537186-6b88-7cde-1cda-06daae5a7738-45396e68" className="floating-number">
                <div className="text-floating">{props.index}</div>
            </div>
            <div id="w-node-_67537186-6b88-7cde-1cda-06daae5a773b-45396e68" className="section-appointment_list-item-details">
                <h1 className="appointment-details">{props.f_name + " " + props.l_name}</h1>
                <div className="list-item-details_dates">
                    <img loading="lazy" src={calenderIcon} alt="" className="icon-size-tiny"/>
                    <div className="text_appointment-date is-grey">
                        {moment(props.a_datetime).format('LLL')}
                        {props.appt.end_time ? (
                            <span>- {moment(props.appt.end_time).format('h:mm a')}</span>
                        ): null}
                    </div>
                    <div className="section_appointment-divider"></div>
                </div>
            </div>
        </div>

    );

    // <div className="df-fdr">
    //   <div className="df-fdr">
    //     <div className="tmp-img">
    //     </div>
    //     <div className="df-fdc mg-1rem">
    //       <div className="f-w700" style={{paddingBottom:'.5rem'}}>
    //         {props.a_id}
    //       </div>
    //       <div className="f-w400 c-gry" style={{paddingBottom:'1.5rem'}}>{
    //         props.name
    //       }</div>
    //       <div className="f-w600" style={{paddingBottom:'.5rem'}}>
    //         {props.a_datetime.toString()}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="pd-1rem c-gry f-w400">#{props.index+1}</div>
    // </div>
}

export const Footer = () => {
    return (
        <div className="section-footer-wrapper">
          <div className="page-padding" style={{backgroundColor:'black'}}>
            <div className="section-footer-content">
                <img src="images/Wordmark-White-1.png" loading="lazy" alt="" className="image-7"/>
              <div className="div-block-6 margin-top-30">
                <div>
                  <p className="paragraph-4">FOR ASSISTANCE</p>
                </div>
              </div>
              <div className="margin-top-30">
                <div className="section-footer-image-grid">
                    <a id="w-node-_99e5448b-ff66-3164-d2bd-0dc06990e622-f511f45a" href="https://wa.me/+918971768946" className="w-inline-block">
                      <img loading="lazy" src={whatsapp} alt="" className="image-footer"/>
                    </a>
                    <a id="w-node-b28849d9-8513-6333-dfcd-7af34ea091ba-f511f45a" href="tel:+918971768946" className="w-inline-block">
                      <img loading="lazy" src={phone} alt="" className="image-footer"/>
                    </a>
                    <a id="w-node-f49a85b1-ab2a-5c07-b8b4-d2f2656122ed-f511f45a" href="mailto:care@rockethealth.app" className="w-inline-block">
                      <img loading="lazy" src={msg} alt="" className="image-footer"/>
                    </a>
                </div>
              </div>
              <div className="margin-top-30">
                <div className="section-footer-copyright">
                  <h1 className="text-copyright">@ 2022 HealthRx Inc. All rights reserved</h1>
                  <div className="div-block-5">
                    <a href="#" target="_blank" className="link-block-3 w-inline-block">
                      <h1 className="text-copyright">Privacy Policy</h1>
                    </a>
                    <div className="verticale-divider"></div>
                    <a href="#" target="_blank" className="link-block-3 w-inline-block">
                      <h1 className="text-copyright">Terms of use</h1>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export const Help = () => {
    return (
        <div className="section-help">
            <div className="section-help_image">
                <img loading="lazy" src={annonce} alt="" className="image-4"/>
            <div className="section-help_out-image">
                <img loading="lazy" src={leaf} alt=""/>
            </div>
            <h1 className="text-heading">Need further help?</h1>
            <p className="paragraph-small">Let’s discuss your doubts, questions. Just <br/>contact us our expert will reach out to you soon!</p>
            <div className="padding-top padding-small">
                <a href="http://wa.me/+918971768946" target="_blank" className="section-appointment_button primary-btn w-button">WhatsApp us</a>
            </div>
            </div>
        </div>
    );
}

export const PatientAppoint : React.FunctionComponent<{
    f_name : string,
    l_name : string,
    a_datetime : Date,
    a_id : number,
}> = (props) => {
    return (
            <div className='w-layout-grid grid'>
                <img id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b5e0-ad32b5ad"
                    sizes="120px"
                    src={pic}
                    loading="lazy"
                    // srcset="images/Frame-17092-1-1-p-500.png 500w, images/Frame-17092-1-1.png 1000w"
                    alt=""
                    className="item-image"></img>
                <div id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b5e1-ad32b5ad" className="section-appointment_list-item-details">
                    <h1 className="appointment-details">
                        Psychiatry
                    </h1>
                    <div className="text_appointment-drname" style={normal}>
                        {props.f_name}{" "}{props.l_name}
                    </div>
                    <div className="div-block-8">
                        <div className="list-item-details_dates">
                            <img loading="lazy" src={calenderIcon} alt="" className="icon-size-tiny"/>
                            <div className="text_appointment-date" style={normal}>
                                {moment(props.a_datetime).format('L')}
                            </div>
                        </div>
                        <div className="list-item-details_dates">
                            <div className="section_appointment-divider">
                                <div>•</div>
                            </div>
                            <div className="text_appointment-time" style={normal}>
                                {moment(props.a_datetime).format('LT')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
 }

 export const PatientAppointDocContain : React.FunctionComponent<{
    f_name : string,
    l_name : string,
    link    : string,
    a_datetime : Date,
    status : string,
    a_id : number,
}> = (props) => {
    return (
    <div className="section-appointment_list-item">
        <div className="w-layout-grid section-appointment_layout-copy">
            <img src={pic} loading="lazy" id="w-node-_874ac227-ef40-338a-b244-90d8060df8d5-c8396e66" sizes="120px"
            // srcset="../images/Frame-17092-1-1-p-500.png 500w, ../images/Frame-17092-1-1.png 1000w" alt=""
            className="item-image"/>
            <div id="w-node-_874ac227-ef40-338a-b244-90d8060df8d6-c8396e66" className="section-appointment_list-item-details">
                <div className="appointment-status-completed">
                    <h1 className="text-appointement-status">{props.status}</h1>
                </div>
                <div className="margin-top-10">
                    <h1 className="appointment-details">Psychiatry</h1>
                </div>
                <div className="text_appointment-drname" style={normal}>{props.f_name}{" "}{props.l_name}</div>
                <div className="list-item-details_dates">
                    <div className="text_appointment-regno" style={normal}>Reg. no: {props.a_id}</div>
                </div>
            </div>
            <div id="w-node-_791d0a9a-e4b4-ea3d-5980-4c788911e476-c8396e66" className="section-appointment-details-copy-copy">
                <div id="w-node-_9c98bc1b-5afe-7c0a-ef67-1c1b5db04d42-c8396e66" className="section-checkins_list-item-details">
                    <div className="text-heading-copy">Appointment details</div>
                    <div className="list-item-details_dates">
                        <img src={calenderIcon} loading="lazy" width="16" alt="" className="icon-size-tiny"/>
                        <div className="text_checkins-date" style={normal}>
                            {moment(props.a_datetime).format("L")}
                        </div>
                        <div className="section_appointment-divider">
                        <div>•</div>
                        </div>
                        <div className="text_checkins-date" style={normal}>{moment(props.a_datetime).format("LT")}</div>
                    </div>
                </div>
                <a href={props.link} target="_blank" id="w-node-_8bb67a82-2a61-ef4f-3026-4ed40374996a-c8396e66" className="div-block" style={{textDecoration:"none"}}>
                    <img src={vedio} loading="lazy" width="25" id="w-node-_5f12b85f-dd06-c0d7-b89c-899cfe80aeb5-c8396e66" alt="" className="icon-size-tiny"/>
                    <h1 className="appointment-details is-orange">Attend Session</h1>
                </a>
            </div>
        </div>
    </div>
    );
 }

export const ComingSoon = () => {
    return(
            <div className="section-no-appointment">
                <div className="section-no-appointment-wrapper">
                    <div className="section-no-appointment_content-wrapper">
                        <h1 className="text-no-appointment">Coming Soon</h1>
                    </div>
                </div>
            </div>
    )
}

export const NotFound : React.FunctionComponent<{
    content : string
}> = (props) => {
    return(
        <div className="anf_container mg-1rem">
            <div className="apt_notfound">{Apt_notFound}</div>
            <div className="f-s20 pd-1rem">No appointments found</div>
            <div className="f-s14 c-gry" style={{textAlign: 'center'}}>
                {props.content}
            </div>
        </div>
    );
}