import React,{useState,useEffect} from 'react';
import { PatientDetail,DoctorDetail } from '../../../@types/Types';
import axios from 'axios';
import { Switch, BrowserRouter as Router ,Route } from "react-router-dom";
import { PatientPersonalDetail,
          PatientShippingAddress,
          DoctorBankDetail,
          DoctorPersonalDetail,
          DoctorSpecialisationDetail
        } from "./EditProfile";
import {PatientProfile,
        DoctorProfile} from "./ReadProfile";

import { ResourceContext } from "../../Context/ContextAPI";

type Profile = {
  Patient : PatientDetail | undefined,
  Doctor  : DoctorDetail | undefined,
}

const ProfileRoute = () => {
  const [userDetail,setUserDetail] = useState<Profile>();

  const useContxtData = React.useContext(ResourceContext);

  useEffect(()=>{
    console.log("entered.............");
    axios.get<any>(process.env.REACT_APP_API_URL + `/api` + (useContxtData?.context.doctor ? `/doctor-profile` : `/patient-profile?`),{
      withCredentials: true,
      headers:{
        'x-rockethealth-auth': window.localStorage.getItem('rh-token')
      }
    })
    .then((response) => {
      useContxtData?.context.doctor ?
        setUserDetail({
          Patient : undefined,
          Doctor  : response.data,
        })
      :
        setUserDetail({
          Patient : response.data,
          Doctor  : undefined,
        })
    }).catch((error) => {
      console.log(error);
    });
  },[])

  return (
    <>
      {/* <Router> */}
      {
        userDetail ?
          userDetail.Patient ?
              <Switch>
                <Route path="/edit/personal-info">
                  <PatientPersonalDetail userDetail={userDetail.Patient}/>
                </Route>
                <Route path="/edit/shipping-address" >
                  <PatientShippingAddress userDetail={userDetail.Patient}/>
                </Route>
                <Route path="/profile">
                  <PatientProfile userDetail={userDetail.Patient}/>
                </Route>
              </Switch>
          : userDetail.Doctor ?
                <Switch>
                  <Route path="/profile">
                    <DoctorProfile userDetail={userDetail.Doctor}/>
                  </Route>
                  <Route path="/edit/bank-detail" >
                    <DoctorBankDetail userDetail={userDetail.Doctor}/>
                  </Route>
                  <Route path="/edit/personal-detail" >
                    <DoctorPersonalDetail userDetail={userDetail.Doctor}/>
                  </Route>
                  <Route path="/edit/specialisation-detail" >
                    <DoctorSpecialisationDetail userDetail={userDetail.Doctor}/>
                  </Route>
                </Switch>
            :
              <div>User details can't get from the database</div>
        :
          <div>User details can't get from the database</div>
      }
      {/* </Router> */}
    </>
  );
}

export default ProfileRoute;
