import * as React from 'react';
import "../../styles/common.css";
import { BrowserRouter as Router,NavLink,Switch,Route } from 'react-router-dom';

import Appointment from "../Pages/Appointment/Appointment";
import Message from "../Pages/Message";
import Order from "../Pages/Order";
import Patients from "../Pages/Patients";
import Calender from "../Pages/Calender";
import Billing from "../Pages/Billing";
import Assessment from "../Pages/Assessment";
import ProfileRoute from "../Pages/Profile/ProfileRoute";
import orders_4 from "../../image/images/audit-4.png";
import orders_5 from "../../image/images/audit-5.png";
import assesmnt_4 from "../../image/images/pages-4.png";
import assesmnt_5 from "../../image/images/pages-5.png";
import profile_4 from "../../image/images/Group-1_3.png";
import profile_5 from "../../image/images/Group-1-1.png";

import cl_4 from "../../image/images/calendar-1-4.png";
import cl_5 from "../../image/images/calendar-1-3.png";

import pi_4 from "../../image/images/Group-3_1.png";
import pi_5 from "../../image/images/Group-2_1.png";

import msg_4 from "../../image/images/Group-2-1_1.png";
import msg_5 from "../../image/images/Group-3-1_1.png";

import apmt_4 from "../../image/images/stayhome-1_1.png";
import apmt_5 from "../../image/images/stayhome.png";

import { ResourceContext } from "../Context/ContextAPI";
import { Console } from 'console';

enum CS{
  Appointment_active,
  Message_active,
  Order_active,
  Assessment_active,
  Profile_active,
  Patients_active,
  Calender_active
}

const tab = "tabs w-inline-block w-tab-link ";

const Slider = () => {

  const useContxtData = React.useContext(ResourceContext);
  const [isActive, setActive] = React.useState<CS>(CS.Appointment_active);

  const setActivescren = (changer : CS) => {
    setActive(changer)
  }

  React.useEffect(() => {

  },[])

  return (
  <>
    <Router>
    <div className="tabs-menu w-tab-menu">
      <NavLink to="/appointments" onClick={()=>setActivescren(CS.Appointment_active)} data-w-tab="Tab 1" className={isActive.valueOf() == CS.Appointment_active.valueOf() ? tab + "w--current" : tab} >
        <div className="tab-icons" >
          <img width="19" loading="lazy" src={isActive.valueOf() == CS.Appointment_active.valueOf() ? apmt_5 : apmt_4} alt="" className="tab-icons_inactive"/>
        </div>
        <div className="tab-title">Appointments</div>
      </NavLink>
      <NavLink to="/messages" onClick={()=>setActivescren(CS.Message_active)} data-w-tab="Tab 2" className={isActive.valueOf() == CS.Message_active.valueOf() ?  tab + "w--current" : tab}>
        <div className="tab-icons" >
          <img loading="lazy" src={isActive.valueOf() == CS.Message_active.valueOf() ? msg_4  : msg_5} alt="" className="tab-icons_inactive"/>
        </div>
        <div className="tab-title">Messages</div>
      </NavLink>
      {
        useContxtData?.context.doctor ?
          <>
            <NavLink to="/Patients" onClick={()=>setActivescren(CS.Patients_active)} data-w-tab="Tab 2" className={isActive.valueOf() == CS.Patients_active.valueOf() ?  tab + "w--current" : tab}>
              <div className="tab-icons" >
                <img loading="lazy" src={isActive.valueOf() == CS.Patients_active.valueOf() ? pi_4  : pi_5} alt="" className="tab-icons_inactive"/>
              </div>
              <div className="tab-title">patients</div>
            </NavLink>
            <NavLink to="/calendar" onClick={()=>setActivescren(CS.Calender_active)} data-w-tab="Tab 2" className={isActive.valueOf() == CS.Calender_active.valueOf() ?  tab + "w--current" : tab}>
              <div className="tab-icons" >
                <img loading="lazy" src={isActive.valueOf() == CS.Calender_active.valueOf() ? cl_4  : cl_5} alt="" className="tab-icons_inactive"/>
              </div>
              <div className="tab-title">Calender</div>
            </NavLink>
          </>
        :
          <>
            <NavLink to="/orders" onClick={()=>setActivescren(CS.Order_active)} data-w-tab="Tab 3" className={isActive.valueOf() == CS.Order_active.valueOf() ? tab + "w--current" : tab}>
              <div className="tab-icons" >
                <img
                sizes="(max-width: 479px) 29vw, (max-width: 767px) 19.1875px, (max-width: 991px) 2vw, 19.1875px"
                // srcset="images/audit-5-p-500.png 500w, images/audit-5.png 660w"
                src={isActive.valueOf() == CS.Order_active.valueOf() ? orders_4  : orders_5} loading="lazy" alt="" className="tab-icons_inactive"/>
              </div>
              <div className="tab-title">Orders</div>
            </NavLink>
            <NavLink to="/assessments" onClick={()=>setActivescren(CS.Assessment_active)} data-w-tab="Tab 4" className={isActive.valueOf() == CS.Assessment_active.valueOf() ? tab + "w--current" : tab}>
              <div className="tab-icons" >
                <img width="19" loading="lazy" src={ isActive.valueOf() == CS.Assessment_active ? assesmnt_4 :  assesmnt_5} alt="" className="tab-icons_inactive"/>
              </div>
              <div className="tab-title">Assessment</div>
            </NavLink>
          </>
        }
      <NavLink to="/profile" onClick={()=>setActivescren(CS.Profile_active)} data-w-tab="Tab 5" className={isActive.valueOf() == CS.Profile_active.valueOf() ?  tab + "w--current" : tab}>
        <div className="tab-icons">
          <img loading="lazy" src={isActive.valueOf() == CS.Profile_active ?  profile_4 : profile_5} alt="" className="tab-icons_inactive"/>
        </div>
        <div className="tab-title">Profile</div>
      </NavLink>
    </div>
    <div className='tab-pane-tab-1'>
      <Switch>
        <Route path="/appointments">
          <Appointment/>
        </Route>
        <Route path="/messages">
          <Message/>
        </Route>
        <Route path="/profile">
          <ProfileRoute/>
        </Route>
        {
          useContxtData?.context.doctor ?
            <>
              <Route path="/patients">
                <Patients/>
              </Route>
              <Route path="/calendar">
                <Calender/>
              </Route>
              <Route path="/billing">
                <Billing/>
              </Route>
            </>
          :
            <>
              <Route path="/orders">
                <Order/>
              </Route>
              <Route path="/assessments">
                <Assessment/>
              </Route>
            </>
        }
      </Switch>
     </div>
    </Router>
   </>
  );
}

export default Slider;