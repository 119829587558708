import React, {useEffect,useContext, useState} from 'react';
import "../styles/common.css";
import "../styles/dashboard-ui-mobile.webflow.css";
import "../styles/normalize.css";
import "../styles/webflow.css";
import Header from "./Layout/Header";
import Slider from "./Layout/Slider";
import axios from 'axios';
import { ResourceContext } from "./Context/ContextAPI";
import {DoctorRouter} from "../components/WizardForm/DoctorRouter";

const Home = () => {

    const useContxtData = useContext(ResourceContext);
    const [reg,setReg] = useState(false);

    useEffect(()=>{
        console.log(useContxtData?.context.doctor);
        if(useContxtData?.context.doctor == true){
            axios.get<any>(process.env.REACT_APP_API_URL + '/api/iscompleted',{
                withCredentials: true,
                headers:{
                    'x-rockethealth-auth': window.localStorage.getItem('rh-token')
                }
            })
            .then((response) => {
                setReg(response.data.iscompleted);
            }).catch((error) => {
                console.log(error);
            });
        }
    },[])

    return (
        <div className="body">
            <div className='main-wrapper'>
                <div className='main-wrapper_mobile'>
                    <Header></Header>
                    {/* {
                        useContxtData?.context.doctor == true && reg == false  ?
                            <DoctorRouter/>
                        : */}
                    <Slider></Slider>
                    {/* } */}
                </div>
            </div>
        </div>
    );
}

export default Home;
