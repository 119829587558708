import { Children, createContext, useState } from 'react';
import { getJSDocDeprecatedTag } from 'typescript';
import { AppContextInterface,DoctorDetail } from '../../@types/Types';

export type DataContextType = {
    context: AppContextInterface;
    setContext: (contextData: AppContextInterface) => void;
}

const getCookie = (name: string) => {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts?.pop()?.split(";").shift();
  };

const defaultValue : AppContextInterface= ({
    doctorDetail: {
        id          : 0,
        first_name  : "",
        last_name   : "",
        email       : "",
        phone_number: "",
        calendar_id: 0,
        prefix      : "",
        photo       : "",
        city        : "",
        languages   : [],
        specialization: [],
        gender      : '',
        dob         : new Date(),
        degree      : [],
        registration_number: "",
        bank_account_number: "",
        ifsc        : "",
        upi_id      : ""
    },
    patientDetail:{
        id          : 0,
        first_name  : "",
        last_name   : "",
        age         : 0,
        gender      : "",
        email       : "",
        phone_number: "",
        emergency_contact: "",
        address_line_2: "",
        city        : "",
        state       : "",
        pincode     : "",
    },
    doctor : window.localStorage.getItem('rh-token-type') === 'doctor'
})




export const ResourceContext = createContext<DataContextType | null>(null);

export const ResourceContextProvider = (props:any) => {

    const [sampleAppContext,handleSampleAppContext] = useState<AppContextInterface>(defaultValue);

    const userValidation = (d:AppContextInterface) => {
        handleSampleAppContext({
            doctorDetail: {
                id          : d.doctorDetail.id,
                first_name  : d.doctorDetail.first_name,
                last_name   : d.doctorDetail.last_name,
                email       : d.doctorDetail.email,
                phone_number: d.doctorDetail.phone_number,
                calendar_id : d.doctorDetail.calendar_id,
                prefix      : d.doctorDetail.prefix,
                photo       : d.doctorDetail.photo,
                city        : d.doctorDetail.city,
                languages   : d.doctorDetail.languages,
                specialization: d.doctorDetail.specialization,
                gender      : d.doctorDetail.gender,
                dob         : d.doctorDetail.dob,
                degree      : d.doctorDetail.degree,
                registration_number: d.doctorDetail.registration_number,
                bank_account_number: d.doctorDetail.bank_account_number,
                ifsc        : d.doctorDetail.ifsc,
                upi_id      : d.doctorDetail.upi_id
            },
            patientDetail:{
                id          : d.patientDetail.id,
                first_name  : d.patientDetail.first_name,
                last_name   : d.patientDetail.last_name,
                age         : d.patientDetail.age,
                gender      : d.patientDetail.gender,
                email       : d.patientDetail.email,
                phone_number: d.patientDetail.phone_number,
                emergency_contact: d.patientDetail.emergency_contact,
                address_line_2: d.patientDetail.address_line_2,
                city        : d.patientDetail.city,
                state       : d.patientDetail.state,
                pincode     : d.patientDetail.pincode,
            },
            doctor: d.doctor
        });
    }

    return (
        <ResourceContext.Provider value={{context: sampleAppContext,setContext: userValidation}}>
            {props.children}
        </ResourceContext.Provider>
    );
}
