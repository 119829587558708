import offers from "../../image/images/Group-2-1.png";

const OfferSection : React.FunctionComponent<{
}> = (props) => {
    return(
        <div className="section-offerings">
        <h1 className="text-heading">Other offerings</h1>
        <div className="section-offerings_layout">
          <a id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b62a-ad32b5ad" 
          href="#" target="_blank" className="link-block-2 w-inline-block">
            <div className="offering-item_wrapper _1">
              <div className="offering-item">
                <h1 className="text-offerings">Mental Health</h1>
                
                <img loading="lazy" src={offers} alt="" className="offering-item-icon"/>
              </div>
            </div>
          </a>
          <a id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b630-ad32b5ad" 
          href="#" target="_blank" className="link-block w-inline-block">
            <div className="offering-item_wrapper _2">
              <div className="offering-item">
                <h1 className="text-offerings">Women&#x27;s Health</h1>
                <img loading="lazy" src={offers} alt="" className="offering-item-icon"/>
              </div>
            </div>
          </a>
          <a id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b636-ad32b5ad" 
          href="#" target="_blank" className="link-block-2 w-inline-block">
            <div className="offering-item_wrapper _3">
              <div className="offering-item">
                <h1 className="text-offerings">Men&#x27;s Health</h1>
                <img loading="lazy" src={offers} alt="" className="offering-item-icon"/>
              </div>
            </div>
          </a>
          <a id="w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b63c-ad32b5ad" 
          href="#" target="_blank" className="link-block-2 w-inline-block">
            <div className="offering-item_wrapper _4">
              <div className="offering-item">
                <h1 className="text-offerings">Supplements</h1>
                <img loading="lazy" src={offers} alt="" className="offering-item-icon"/>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
}

export default OfferSection;