import React,{useState,useEffect} from 'react';
import { PatientDetail,DoctorDetail } from '../../../@types/Types';
import {ShippingIcon,
  PersonalIcon,
  BankIcon,
  SpecializationIcon
} from "../../../image/Icons";
import personal from "../../../image/images/Group-3-1.png";
import shipping from "../../../image/images/Vector-15-1.png";

import {ReadComp,ProfileHeader,GrpReadComp, Footer} from "../../Elements/Common";

const eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  window.localStorage.setItem('rh-token', null);
  window.localStorage.setItem('rh-token-type', null);
}

export const PatientProfile :  React.FunctionComponent<{
    userDetail: PatientDetail
}> = (props) => {


  const handleLogout = () => {
    eraseCookie("p_token");
    window.location.href = "/login"
  }

  return (
    <>
      <div className="main-wrapper_mobile">
        <div className='page-padding'>
          <ProfileHeader tittle={"Personal Info"} icon={personal} link="/edit/personal-info"/>
          <div>
            <ReadComp name={"Name"} value={(props.userDetail.first_name || '') + " " + (props.userDetail.last_name || '')}/>
            <GrpReadComp name1={"Age"} value1={props.userDetail.age} name2={"Gender"} value2={props.userDetail.gender}/>
            <ReadComp name={"Email"} value={props.userDetail.email}/>
            <ReadComp name={"Phone number"} value={props.userDetail.phone_number}/>
            <ReadComp name={"Emergency contact"} value={props.userDetail.emergency_contact}/>
          </div>
          {/* <div>
            <ProfileHeader tittle={"Shipping Address"} icon={shipping} link="/edit/shipping-address"/>
            <div>
              <ReadComp name={"home"} value={[props.userDetail.address_line_2,props.userDetail.city,props.userDetail.state].join(", ") + " - " + props.userDetail.pincode}/>
            </div>
          </div> */}
          <div className='section-divider'/>
          <div className="section-logout-btn">
            <a onClick={handleLogout} className="section-primary_button secondary-btn w-button">Logout</a>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export const DoctorProfile :  React.FunctionComponent<{
  userDetail: DoctorDetail
}> = (props) => {

  const handleLogout = () => {
    eraseCookie("d_token");
    window.location.href = "/doctor-login"
  }

return (
  <>
    <div className="main-wrapper_mobile">
      <div className='page-padding'>
          <ProfileHeader tittle={"Personal Info"} icon={personal} link="/edit/personal-detail"/>
          <div>
            <ReadComp name={"Name"} value={props.userDetail.first_name + " " + (props.userDetail.last_name || '')}/>
            <ReadComp name={"Gender"} value={props.userDetail.gender}/>
            <ReadComp name={"Email"} value={props.userDetail.email}/>
            <ReadComp name={"Phone number"} value={props.userDetail.phone_number}/>
            <ReadComp name={"Dob"} value={props.userDetail.dob}/>
            <ReadComp name={"Languages"} value={props.userDetail.languages ? props.userDetail.languages.join(" ,") : ""}/>
            <ReadComp name={"Prefix"} value={props.userDetail.prefix}/>
          </div>
          <ProfileHeader tittle={"Specialisation details"} icon={personal} link="/edit/specialisation-detail"/>
          <div>
            <ReadComp name={"Specialisation"} value={props.userDetail.specialization != null ? props.userDetail.specialization.join(" ,") : ""}/>
            <ReadComp name={"Degree"} value={props.userDetail.degree != null ? props.userDetail.degree.join(" ,") : ""}/>
            <ReadComp name={"Registration number"} value={props.userDetail.registration_number}/>
          </div>
          <ProfileHeader tittle={"Bank details"} icon={personal} link="/edit/bank-detail"/>
          <div>
            <ReadComp name={"Account number"} value={props.userDetail.bank_account_number}/>
            <ReadComp name={"IFSC"} value={props.userDetail.ifsc}/>
            <ReadComp name={"UPI ID"} value={props.userDetail.upi_id}/>
          </div>
          <div className='section-divider'/>
          <div className="section-logout-btn">
            <a onClick={handleLogout} className="section-primary_button secondary-btn w-button">Logout</a>
          </div>
        </div>
    </div>
    <Footer/>
  </>
);
}