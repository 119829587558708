import React from 'react';
import { ComingSoon,Footer } from '../Elements/Common';

const Billing = () => {
  return (
    <>
      <ComingSoon/>
      <Footer/>
    </>
  );
}

export default Billing;