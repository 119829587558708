import React,{useEffect} from 'react';
// import {Goback,Med} from "../../image/Icons";
// import { Medicines,
//          Option, 
//          PatientDetail} from '../../@types/Types';
import axios from 'axios';
// import MultiDropDown from "../Elements/MultiDropDown";
// import { NavLink,useHistory } from "react-router-dom";
import { DoctorAppointmentDetail,Survey } from '../../@types/Types';
import question from "../../image/images/Vector-12.png";
import {Heading,PersonalInfo,SubHeading} from "../Elements/Common";

export const LabReport : React.FunctionComponent<{
    aptDetail: DoctorAppointmentDetail | undefined
  }> = (props) => {

    const [survey,setSurvey] = React.useState<Survey[]>([]);

    useEffect(()=>{
        if(props.aptDetail && props.aptDetail.Patient )
          axios.get<Survey[]>(process.env.REACT_APP_API_URL + `/api/patient-survey?patient_id=`+props.aptDetail.Patient.id,{withCredentials: true})
          .then((response) => {
            setSurvey(response.data);
          }).catch((error) => {
            console.log(error);
            setSurvey([]);
          })
       
    },[])

    return (
        props.aptDetail ?
            <div className='page-padding'>
                <Heading heading='Survey & Lab reports' url={`/appointments/${props.aptDetail.id}`} />
                <div className="section-divider"></div>
                <PersonalInfo  patient={props.aptDetail.Patient}/>
                <SubHeading iconPath={question} tittle="Q&A"/>
                {
                    survey.map((val,index) => {
                    return  <QuestionAns question={val.question} answer={val.answer} index={index+1}/>;
                    })
                }
                <div className='margin-top-30'>
                    <button type='submit' className="section_button-wrapper primary-btn w-button">
                        Download
                    </button>
                </div>
            </div>
        :
            <div>Appoitment details can't be found</div>
    );
}

const QuestionAns : React.FunctionComponent<{
    question : string,
    answer   : string,
    index    : number
  }> = (props) => {
    return (
        <div className="we-have-sent-you-the-otp-to-83009-88763">
            <div className="section-faq">
                <div className="padding-inner">
                    <h1 className="text-size-regular">{props.index}.{props.question}</h1>
                    <div className="margin-top-10">
                    <p>{props.answer}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
