import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { PatientAppointmentDetail,DoctorAppointmentDetail } from '../../../@types/Types';
// import {Apt_notFound} from "../../image/Icons";
import { ResourceContext } from "../../Context/ContextAPI";
import {AppoitContain,NotFound,PatientAppoint,Help,Footer} from "../../Elements/Common";
import OfferSection from "../../Elements/OfferSection";
import { AptDetail } from "./AptDetail";
import { Switch, BrowserRouter as Router ,Route,useRouteMatch, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {DoctorAppointmentRoute,PatientAppointmentRoute} from "./AppointmentRoute";


const TodayAptSummary : React.FunctionComponent<{
  totalApt : number
}> = (props) => {
  return (
    <div className="section-appointment-time-wrapper">
      <div>
        <div className="section-appointment-time-date-left">
          <h1 className="appointment-details">Today</h1>
        </div>
        <div className="div-block-2"></div>
      </div>
      <div id="w-node-f74d0568-ddc7-4df1-9eb2-6d8cf08a9cd0-45396e68" className="section-appointment-appointments">
        <h1 className="appointment-details">{props.totalApt} Appointments</h1>
      </div>
    </div>
  );
}

const classNameAdded = (status : stat,Compare:stat) => {
  return status === Compare ? "w--current section-status_button-active w-inline-block w-tab-link " : "section-status_button-active w-inline-block w-tab-link"
}

enum stat {
  Upcoming = "Upcoming",
  Completed = "Completed"
}

const Appointment = () => {
  const match = useRouteMatch();
  let location = useLocation();
  const [status,setStatus] = useState(stat.Upcoming);
  const [apointments,setApointments] = useState<PatientAppointmentDetail[]>([]);
  const [apt,setApt] = useState<DoctorAppointmentDetail>();
  const [dapointments,setDApointments] = useState<DoctorAppointmentDetail[]>([]);
  const [papt,setpApt] = useState<PatientAppointmentDetail>();

  const useContxtData = React.useContext(ResourceContext);

  useEffect(()=>{
    // @ts-ignore
    useContxtData?.context.doctor === true ?
      axios.get<DoctorAppointmentDetail[]>(process.env.REACT_APP_API_URL + `/api/doctor-appointment?status=`+status,{
        withCredentials: true,
        headers:{
          'x-rockethealth-auth': window.localStorage.getItem('rh-token')
        }
      })
      .then((response) => {
        setDApointments(response.data);
        setApt(undefined);
      }).catch((error) => {
        console.log(error);
        setDApointments([]);
        setApt(undefined);
      })
    :
      axios.get<PatientAppointmentDetail[]>(process.env.REACT_APP_API_URL + "/api/patient-appointment?status="+status,{
        withCredentials: true,
        headers:{
          'x-rockethealth-auth': window.localStorage.getItem('rh-token')
        }
      })
      .then((response) => {
        setApointments(response.data);
        setpApt(undefined);
      }).catch((error) => {
        console.log(error);
        setApointments([]);
        setpApt(undefined);
      })
  },[status, location])

  return (
    <>
      <Router>
        <Switch>
          <Route path={'/appointments/:id'}>
            {
              useContxtData?.context.doctor === true ?
                <DoctorAppointmentRoute aptDetail={apt}/>
              :
                <PatientAppointmentRoute aptDetail={papt}/>
            }
          </Route>
          <Route exact path={'/appointments'}>
            <>
              <div className="section-status_wrapper w-tab-menu" >
                <a data-w-tab="Tab 1" className={classNameAdded(status,stat.Upcoming)} onClick={()=>{setStatus(stat.Upcoming)}}>
                  <div>Upcoming</div>
                </a>
                <a data-w-tab="Tab 2" className={classNameAdded(status,stat.Completed)} onClick={()=>{setStatus(stat.Completed)}}>
                  <div>Completed</div>
                </a>
              </div>
              <div className='section-divider'/>
              {
                useContxtData?.context.doctor === true ?
                  <TodayAptSummary totalApt={ useContxtData?.context.doctor === true ? dapointments.length : apointments.length } />
                :
                  <></>
              }
              {
                apointments.length <= 0 && dapointments.length <= 0 ?
                  <NotFound content="Oh, You don't have any appointments. So sit back & relax"/>
                :
                  <div className="section-appointment_list">
                    {
                      useContxtData?.context.doctor === true ?
                        dapointments.map((ed,index)=>{
                          return (
                            <div className="section-appointment_list-item" key={ed.appoinment_number}>
                              <AppoitContain
                                doctor={true}
                                appt={ed}
                                a_id={ed.appoinment_number}
                                a_datetime={ed.appointment_datetime}
                                f_name={ed.Patient.first_name}
                                l_name={ed.Patient.last_name != null ? ed.Patient.last_name : ""}
                                index={index}
                              />
                              <div className="section-appointment_list-buttons">
                                <NavLink to={`/appointments/${ed.id}`} onClick={()=>{setApt(ed)}} className={(status == stat.Upcoming ? "section-appointment_button" : "section_button-wrapper") + " secondary-btn w-button"} >
                                        Details
                                </NavLink>
                                {
                                  status == stat.Upcoming ?
                                    <a target="_blank" href={ed.appointment_link} className="section-appointment_button primary-btn w-button">Attend</a>
                                  :
                                    <></>
                                }
                              </div>
                            </div>
                          );
                        })
                      :
                        apointments.map((ed,index)=>{
                          return (
                            <div className="section-appointment_list-item" key={ed.appoinment_number}>
                              <PatientAppoint
                                a_id={ed.appoinment_number}
                                f_name={( ed.Doctor.prefix === null || ed.Doctor.prefix.length <= 0 ? "" : ed.Doctor.prefix + '. ')
                                + ed.Doctor.first_name}
                                l_name={ed.Doctor.last_name != null ? ed.Doctor.last_name : ""}
                                a_datetime={ed.appointment_datetime} />
                              <div className="section-appointment_list-buttons">
                                <NavLink to={`/appointments/${ed.id}`} onClick={()=>{setpApt(ed)}} className={(status == stat.Upcoming ? "section-appointment_button" : "section_button-wrapper") + " secondary-btn w-button"}>
                                    Details
                                </NavLink>
                                {
                                  status == stat.Upcoming ?
                                    <a target="_blank" href={ed.appointment_link} className="section-appointment_button primary-btn w-button">Attend</a>
                                  :
                                    <></>
                                }
                              </div>
                            </div>
                          );
                        })
                    }
                  </div>
              }
              {
                useContxtData?.context.doctor === true || status == stat.Completed ?
                  <>
                    <div className='section-divider'/>
                    <Footer/>
                  </>
                :
                  <>
                    <div className='section-divider'/>
                    <OfferSection/>
                    <div className='section-divider'/>
                    <Help/>
                    <div className='section-divider'/>
                    <Footer/>
                  </>
              }
            </>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default Appointment;
